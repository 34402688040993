<template>
  <div class="flix-flex flix-flex-inline flix-flex-center flix-btn-group">
    <a href="#" @click.prevent="changeDuration(-1)" class="flix-btn">
      <icon class="flix-desktop" id="chevron-left" />
      <icon class="flix-mobile" id="chevron-up" />
    </a>
    <div class="flix-form-control" v-html="settings.values[getValue()]" />
    <a href="#" @click.prevent="changeDuration(1)" class="flix-btn">
      <icon class="flix-desktop" id="chevron-right" />
      <icon class="flix-mobile" id="chevron-down" />
    </a>
  </div>
</template>
<script>
export default {
  components: {},
  props: {
    settings: Object,
    callback: Function
  },
  data() {
    return {
      data: this.getValue()
    };
  },
  methods: {
    getValue() {
      if (!this.settings.value) {
        return this.settings.default;
      }
      return this.settings.value;
    },
    changeDuration(add) {
      var keys = Object.keys(this.settings.values);
      var index = keys.indexOf(this.data + "");
      index = index + add;

      if (index < 0) {
        index = keys.length - 1;
      }
      if (index === keys.length) {
        index = 0;
      }

      this.data = keys[index];
      this.callback(this.data);
    }
  }
};
</script>
<style lang="sass" scoped></style>
